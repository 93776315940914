import React, { useState } from "react";
import DonationTable from "./Table/DonationTable";
import SubTable from "./SubTable/SubscriptionTable";

const Dashboard = () => {
  const [showDonations, setShowDonations] = useState(true);

  return (
    <div className="container mx-auto mt-8">
      <div className="flex justify-center mb-4">
        <button
          className={`px-4 py-2 mx-2 rounded ${
            showDonations ? "bg-blue-500" : "bg-gray-500"
          } text-white`}
          onClick={() => setShowDonations(true)}
        >
          Show Donations
        </button>
        <button
          className={`px-4 py-2 mx-2 rounded ${
            showDonations ? "bg-gray-500" : "bg-blue-500"
          } text-white`}
          onClick={() => setShowDonations(false)}
        >
          Show Subs
        </button>
      </div>
      {showDonations ? <DonationTable /> : <SubTable />}
    </div>
  );
};

export default Dashboard;
