import TableHeaderEntry from "../Table/TableHeanderEntry";

export default function SubTableHeader() {
  return (
    <thead>
      <tr>
        <TableHeaderEntry text="ID" />
        <TableHeaderEntry text="Gifter" />
        <TableHeaderEntry text="Sub Count" />
        <TableHeaderEntry text="Status" />
        <TableHeaderEntry text="Actions" />
      </tr>
    </thead>
  );
}
