import { useContext } from "react";
import { DonationsContext } from "../../store/donations-context";

export default function DonationTableEntry({ donation }) {
  const { acceptDonation, rejectDonation } = useContext(DonationsContext);

  const rowClassName = donation.approved
    ? "bg-green-800 text-green-100"
    : donation.rejected
    ? "bg-red-800 text-red-100"
    : "bg-zinc-800 text-white";

  const statusText = donation.approved
    ? "Approved"
    : donation.rejected
    ? "Rejected"
    : "Pending";

  return (
    <tr
      key={donation.id}
      className={`${rowClassName} border-b border-gray-700`}
    >
      <td className="py-2 px-4">{donation.id}</td>
      <td className="py-2 px-4">{donation.from_name}</td>
      <td className="py-2 px-4">{donation.amount}</td>
      <td className="py-2 px-4">{donation.currency}</td>
      <td className="py-2 px-4">{donation.message}</td>
      <td className="py-2 px-4">{donation.ai_keyword}</td>
      <td className="py-2 px-4">{statusText}</td>
      <td className="py-2 px-4">
        <button
          className={`px-4 py-2 rounded mr-2 ${
            donation.rejected
              ? "bg-gray-400 text-white"
              : "bg-green-500 text-white"
          }`}
          onClick={() => acceptDonation(donation.id)}
          disabled={donation.rejected}
        >
          Approve
        </button>
        <button
          className={`px-4 py-2 rounded ${
            donation.rejected
              ? "bg-gray-400 text-white"
              : "bg-red-500 text-white"
          }`}
          onClick={() => rejectDonation(donation.id)}
        >
          Reject
        </button>
      </td>
    </tr>
  );
}
