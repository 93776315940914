import React from "react";
import SubTableHeader from "./SubTableHeader";
import SubTableEntries from "./SubTableEntries";

const SubTable = () => {
  return (
    <table className="min-w-full bg-zinc-800">
      <SubTableHeader />
      <SubTableEntries />
    </table>
  );
};

export default SubTable;
