import { useContext } from "react";
import { SubsContext } from "../../store/subs-context";

export default function SubTableEntry({ sub }) {
  const { acceptSub, rejectSub } = useContext(SubsContext);

  const rowClassName = sub.approved
    ? "bg-green-800 text-green-100"
    : sub.rejected
    ? "bg-red-800 text-red-100"
    : "bg-zinc-800 text-white";

  const statusText = sub.approved
    ? "Approved"
    : sub.rejected
    ? "Rejected"
    : "Pending";

  return (
    <tr key={sub.id} className={`${rowClassName} border-b border-gray-700`}>
      <td className="py-2 px-4">{sub.id}</td>
      <td className="py-2 px-4">{sub.gifter_name}</td>
      <td className="py-2 px-4">{sub.sub_count}</td>
      <td className="py-2 px-4">{sub.ai_keyword}</td>
      <td className="py-2 px-4">{statusText}</td>
      <td className="py-2 px-4">
        <button
          className={`px-4 py-2 rounded mr-2 ${
            sub.rejected ? "bg-gray-400 text-white" : "bg-green-500 text-white"
          }`}
          onClick={() => acceptSub(sub.id)}
          disabled={sub.rejected}
        >
          Approve
        </button>
        <button
          className={`px-4 py-2 rounded ${
            sub.rejected ? "bg-gray-400 text-white" : "bg-red-500 text-white"
          }`}
          onClick={() => rejectSub(sub.id)}
        >
          Reject
        </button>
      </td>
    </tr>
  );
}
