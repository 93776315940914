import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Dashboard from "./components/Dashboard";
import Header from "./components/Header";
import DonationContextProvider from "./store/donations-context";
import SubsContextProvider from "./store/subs-context";
import AuthContextProvider, { AuthContext } from "./store/auth-context";
import Register from "./components/Auth/Register";
import Login from "./components/Auth/Login";
import AudioPlayer from "./components/AudioPlayer/AudioPlayer";
import { WebsocketProvider } from "./store/WebSocketContext";
import NewAudioPlayer from "./components/AudioPlayer/NewAudioPlayer";
import AudioPlayer2 from "./components/AudioPlayer/AudioPlayer2";

const ProtectedRoute = ({ children }) => {
  const { token } = React.useContext(AuthContext);
  return token ? children : <Navigate to="/login" />;
};

const AuthenticatedApp = () => (
  <AuthContextProvider>
    <DonationContextProvider>
      <SubsContextProvider>
        <div className="bg-zinc-900 min-h-screen">
          <Routes>
            <Route
              path="/"
              element={
                <ProtectedRoute>
                  <Header />
                  <Dashboard />
                </ProtectedRoute>
              }
            />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
          </Routes>
        </div>
      </SubsContextProvider>
    </DonationContextProvider>
  </AuthContextProvider>
);

function App() {
  return (
    <Router>
      <Routes>
        <Route
          path="/audioplayer"
          element={
            <WebsocketProvider>
              <AudioPlayer />
            </WebsocketProvider>
          }
        />
        <Route
          path="/newaudioplayer"
          element={
            <WebsocketProvider>
              <NewAudioPlayer />
            </WebsocketProvider>
          }
        />
        <Route
          path="/audioplayer2"
          element={
            <WebsocketProvider>
              <AudioPlayer2 />
            </WebsocketProvider>
          }
        />
        <Route path="/*" element={<AuthenticatedApp />} />
      </Routes>
    </Router>
  );
}

export default App;
