import TableHeaderEntry from "./TableHeanderEntry";

export default function DonationTableHeader() {
  return (
    <thead>
      <tr>
        <TableHeaderEntry text="ID" />
        <TableHeaderEntry text="From" />
        <TableHeaderEntry text="Amount" />
        <TableHeaderEntry text="Currency" />
        <TableHeaderEntry text="Message" />
        <TableHeaderEntry text="Actions" />
      </tr>
    </thead>
  );
}
