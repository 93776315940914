import React, {
  createContext,
  useReducer,
  useContext,
  useEffect,
  useState,
} from "react";
import { AuthContext } from "./auth-context";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

export const SubsContext = createContext({
  subs: [],
  addSubs: () => {},
  acceptSub: () => {},
  rejectSub: () => {},
  setPage: () => {},
  currentPage: 1,
  totalPages: 1,
});

function subReducer(state, action) {
  switch (action.type) {
    case "ADD_SUBS":
      return {
        ...state,
        subs: action.payload.subs || [], // Ensure subs is always an array
        currentPage: action.payload.currentPage,
        totalPages: action.payload.totalPages,
      };
    case "ACCEPT_SUB":
      return {
        ...state,
        subs: state.subs.map((sub) =>
          sub.id === action.payload
            ? { ...sub, approved: true, rejected: false }
            : sub
        ),
      };
    case "REJECT_SUB":
      return {
        ...state,
        subs: state.subs.map((sub) =>
          sub.id === action.payload
            ? { ...sub, approved: false, rejected: true }
            : sub
        ),
      };
    default:
      return state;
  }
}

export default function SubsContextProvider({ children }) {
  const [subsState, subsDispatch] = useReducer(subReducer, {
    subs: [],
    currentPage: 1,
    totalPages: 1,
  });

  const { token } = useContext(AuthContext);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (token) {
      const fetchSubs = async (page) => {
        try {
          const requestOptions = {
            method: "GET",
            headers: { Authorization: `${token}` },
          };

          const response = await fetch(
            `${backendUrl}/aggregated-gifted-subs?limit=20&offset=${
              (page - 1) * 20
            }`,
            requestOptions
          );

          if (!response.ok) {
            const errorText = await response.text();
            throw new Error(
              `Fetch subs failed: ${response.status} ${errorText}`
            );
          }

          const data = await response.json();
          subsDispatch({
            type: "ADD_SUBS",
            payload: {
              subs: data.aggregated_gifted_subs || [], // Ensure subs is always an array
              currentPage: page,
              totalPages: data.total_pages,
            },
          });
        } catch (error) {
          console.error("Fetch subs error:", error);
        }
      };

      fetchSubs(currentPage);
      const intervalId = setInterval(() => fetchSubs(currentPage), 5000); // Refresh every 60 seconds

      return () => clearInterval(intervalId); // Cleanup interval on component unmount
    }
  }, [token, currentPage]);

  function handleAddSubs(subs) {
    subsDispatch({
      type: "ADD_SUBS",
      payload: subs,
    });
  }

  async function handleAcceptSub(subID) {
    try {
      const requestOptions = {
        method: "PUT",
        headers: { Authorization: `${token}` },
      };

      const response = await fetch(
        `${backendUrl}/aggregated-gifted-subs/${subID}/approve`,
        requestOptions
      );

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Accept sub failed: ${response.status} ${errorText}`);
      }

      subsDispatch({
        type: "ACCEPT_SUB",
        payload: subID,
      });
    } catch (error) {
      console.error("Accept sub error:", error);
    }
  }

  async function handleRejectSub(subID) {
    try {
      const requestOptions = {
        method: "PUT",
        headers: { Authorization: `${token}` },
      };

      const response = await fetch(
        `${backendUrl}/aggregated-gifted-subs/${subID}/reject`,
        requestOptions
      );

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Reject sub failed: ${response.status} ${errorText}`);
      }

      subsDispatch({
        type: "REJECT_SUB",
        payload: subID,
      });
    } catch (error) {
      console.error("Reject sub error:", error);
    }
  }

  const ctxValue = {
    subs: subsState.subs,
    addSubs: handleAddSubs,
    acceptSub: handleAcceptSub,
    rejectSub: handleRejectSub,
    setPage: setCurrentPage,
    currentPage: subsState.currentPage,
    totalPages: subsState.totalPages,
  };

  return (
    <SubsContext.Provider value={ctxValue}>{children}</SubsContext.Provider>
  );
}
