import React, { createContext, useReducer, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

export const AuthContext = createContext({
  token: null,
  login: () => {},
  register: () => {},
  logout: () => {},
  refreshToken: () => {},
});

const initialState = {
  token: localStorage.getItem("token") || null,
  refreshToken: localStorage.getItem("refresh_token") || null,
};

const authReducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
    case "REFRESH_TOKEN":
      localStorage.setItem("token", action.payload.token);
      localStorage.setItem("refresh_token", action.payload.refreshToken);
      return {
        ...state,
        token: action.payload.token,
        refreshToken: action.payload.refreshToken,
      };
    case "LOGOUT":
      localStorage.removeItem("token");
      localStorage.removeItem("refresh_token");
      return { token: null, refreshToken: null };
    default:
      return state;
  }
};

export default function AuthContextProvider({ children }) {
  const [state, dispatch] = useReducer(authReducer, initialState);
  const navigate = useNavigate();
  const refreshLock = useRef(false);

  useEffect(() => {
    const interval = setInterval(() => {
      refreshToken();
    }, 14 * 60 * 1000); // 14 minutes
    return () => clearInterval(interval);
  }, [state.refreshToken]);

  const fetchWithHandling = async (url, options, successActionType) => {
    try {
      const response = await fetch(url, options);
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`${response.status}: ${errorText}`);
      }
      const data = await response.json();
      if (successActionType) {
        dispatch({
          type: successActionType,
          payload: { token: data.token, refreshToken: data.refresh_token },
        });
      }
      return data;
    } catch (error) {
      console.error("Error:", error);
      if (successActionType === "REFRESH_TOKEN") {
        logout();
      }
      throw error; // Propagate error for further handling if needed
    }
  };

  const login = (email, password) =>
    fetchWithHandling(
      `${backendUrl}/login`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email, password }),
      },
      "LOGIN"
    ).then(() => navigate("/"));

  const register = (email, username, password) =>
    fetchWithHandling(
      `${backendUrl}/register`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email, username, password }),
      },
      "LOGIN"
    ).then(() => navigate("/"));

  const refreshToken = async () => {
    if (refreshLock.current || !state.refreshToken) return;
    refreshLock.current = true;

    try {
      await fetchWithHandling(
        `${backendUrl}/refresh`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            refreshToken: state.refreshToken,
            oldToken: state.token,
          }),
        },
        "REFRESH_TOKEN"
      );
    } catch (error) {
      console.error("Refresh token failed", error);
      // Optional: handle specific errors, e.g., token expiration
    } finally {
      refreshLock.current = false;
    }
  };

  const logout = () => {
    dispatch({ type: "LOGOUT" });
    navigate("/login");
  };

  return (
    <AuthContext.Provider
      value={{ token: state.token, login, register, logout, refreshToken }}
    >
      {children}
    </AuthContext.Provider>
  );
}
