import React, { createContext, useEffect, useRef, useState } from "react";

export const WebsocketContext = createContext(null);

const backendUrl = process.env.REACT_APP_BACKEND_URL;
const websocketUrl = process.env.REACT_APP_BACKEND_WS;
const WS_URL = `${websocketUrl}/donations/ws`;

export const WebsocketProvider = ({ children }) => {
  const [isReady, setIsReady] = useState(false);
  const [val, setVal] = useState(null);
  const ws = useRef(null);
  const pingInterval = useRef(null);

  useEffect(() => {
    const connect = () => {
      const socket = new WebSocket(WS_URL);

      socket.onopen = () => {
        setIsReady(true);
        console.log("WebSocket connected");

        // Start sending ping messages
        pingInterval.current = setInterval(() => {
          if (socket.readyState === WebSocket.OPEN) {
            socket.send(JSON.stringify({ type: "ping" }));
          }
        }, 30000); // Send a ping every 30 seconds
      };

      socket.onclose = () => {
        setIsReady(false);
        console.log("WebSocket disconnected, attempting to reconnect...");
        clearInterval(pingInterval.current);
        setTimeout(connect, 1000); // Attempt to reconnect after 1 second
      };

      socket.onmessage = (event) => {
        const data = JSON.parse(event.data);
        if (data.type === "pong") {
          // Handle pong response if needed
        } else {
          const randomKey = Math.random().toString(36).substring(2, 15);
          setVal(`${backendUrl}/audio/${data.id}?key=${randomKey}`);
        }
      };

      ws.current = socket;
    };

    connect();

    return () => {
      if (ws.current) {
        ws.current.close();
      }
      if (pingInterval.current) {
        clearInterval(pingInterval.current);
      }
    };
  }, []);

  return (
    <WebsocketContext.Provider value={[isReady, val]}>
      {children}
    </WebsocketContext.Provider>
  );
};
