import React, { useContext, useEffect, useState } from "react";
import ReactAudioPlayer from "react-audio-player";
import { WebsocketContext } from "../../store/WebSocketContext";

export default function AudioPlayer() {
  const [ready, val] = useContext(WebsocketContext);
  const [userInteracted, setUserInteracted] = useState(false);

  useEffect(() => {
    if (val) {
      console.log(`Received new audio URL: ${val}`);
    }
  }, [val]);

  const handleUserInteraction = () => {
    setUserInteracted(true);
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-900 text-white">
      <h1 className="text-4xl mb-8">Donation Audio Player</h1>
      {!userInteracted ? (
        <button
          onClick={handleUserInteraction}
          className="px-4 py-2 bg-blue-500 text-white rounded"
        >
          Click to enable audio
        </button>
      ) : (
        <>
          <p className="text-sm mb-4">
            Connection status: {ready ? "Connected" : "Disconnected"}
          </p>
          {ready ? (
            val ? (
              <div className="w-full max-w-md">
                <ReactAudioPlayer
                  src={val}
                  autoPlay
                  controls
                  className="w-full"
                />
              </div>
            ) : (
              <p className="text-xl">Waiting for new donations...</p>
            )
          ) : (
            <p className="text-xl text-red-500">
              WebSocket disconnected, attempting to reconnect...
            </p>
          )}
        </>
      )}
    </div>
  );
}
