import { useContext } from "react";
import { DonationsContext } from "../../store/donations-context";
import DonationTableEntry from "./DonationTableEntry";

export default function DonationTableEntries() {
  const { donations, currentPage, totalPages, setPage } =
    useContext(DonationsContext);

  const handlePreviousPage = () => {
    if (currentPage > 1) setPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setPage(currentPage + 1);
  };

  return (
    <>
      <tbody>
        {donations.length > 0 ? (
          donations.map((donation) => (
            <DonationTableEntry key={donation.id} donation={donation} />
          ))
        ) : (
          <tr>
            <td colSpan="8" className="text-center py-4">
              No donations found.
            </td>
          </tr>
        )}
      </tbody>
      <tfoot>
        <tr>
          <td colSpan="8" className="text-center py-4">
            <div className="flex justify-center items-center space-x-4">
              <button
                onClick={handlePreviousPage}
                disabled={currentPage === 1}
                className={`px-3 py-1 border rounded ${
                  currentPage === 1
                    ? "bg-gray-400"
                    : "bg-blue-500 hover:bg-blue-700"
                } text-white`}
              >
                Previous
              </button>
              <span className="text-white">{`Page ${currentPage} of ${totalPages}`}</span>
              <button
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
                className={`px-3 py-1 border rounded ${
                  currentPage === totalPages
                    ? "bg-gray-400"
                    : "bg-blue-500 hover:bg-blue-700"
                } text-white`}
              >
                Next
              </button>
            </div>
          </td>
        </tr>
      </tfoot>
    </>
  );
}
