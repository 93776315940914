import React, { useContext, useEffect, useState, useRef } from "react";
import { Howl } from "howler";
import { WebsocketContext } from "../../store/WebSocketContext";

export default function AudioPlayer() {
  const [ready, val] = useContext(WebsocketContext);
  const [userInteracted, setUserInteracted] = useState(true);
  const currentSoundRef = useRef(null);

  useEffect(() => {
    if (val && userInteracted) {
      // Stop the current sound if it's playing
      if (currentSoundRef.current) {
        currentSoundRef.current.stop();
      }

      // Create a new sound instance
      const newSound = new Howl({
        src: [val],
        volume: 1.0,
        autoplay: true,
        loop: false,
        preload: true,
        html5: true,
        onend: function () {
          console.log("Sound playback finished.");
        },
        onloaderror: function (id, err) {
          console.error("Load error:", err);
        },
        onplayerror: function (id, err) {
          console.error("Play error:", err);
          newSound.once("unlock", function () {
            newSound.play();
          });
        },
      });

      // Play the new sound
      newSound.play();

      // Store the new sound instance in the ref
      currentSoundRef.current = newSound;
    }
  }, [val, userInteracted]);

  const handleUserInteraction = () => {
    setUserInteracted(true);
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-900 text-white">
      <h1 className="text-4xl mb-8">Donation Audio Player</h1>
      {/* <button
        onClick={handleUserInteraction}
        className="px-4 py-2 bg-blue-500 text-white rounded"
      >
        Click to enable audio
      </button> */}
      {userInteracted && (
        <>
          <p className="text-sm mb-4">
            Connection status: {ready ? "Connected" : "Disconnected"}
          </p>
          {ready ? (
            val ? (
              <p className="text-xl">Playing new donation audio...</p>
            ) : (
              <p className="text-xl">Waiting for new donations...</p>
            )
          ) : (
            <p className="text-xl text-red-500">
              WebSocket disconnected, attempting to reconnect...
            </p>
          )}
        </>
      )}
    </div>
  );
}
