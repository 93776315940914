import React, {
  createContext,
  useReducer,
  useContext,
  useEffect,
  useState,
} from "react";
import { AuthContext } from "./auth-context";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

export const DonationsContext = createContext({
  donations: [],
  addDonations: () => {},
  acceptDonation: () => {},
  rejectDonation: () => {},
  replayDonation: () => {},
  setPage: () => {},
  currentPage: 1,
  totalPages: 1,
});

function donationReducer(state, action) {
  switch (action.type) {
    case "ADD_DONATIONS":
      return {
        ...state,
        donations: action.payload.donations || [], // Ensure donations is always an array
        currentPage: action.payload.currentPage,
        totalPages: action.payload.totalPages,
      };
    case "ACCEPT_DONATION":
      return {
        ...state,
        donations: state.donations.map((donation) =>
          donation.id === action.payload
            ? { ...donation, approved: true, rejected: false }
            : donation
        ),
      };
    case "REJECT_DONATION":
      return {
        ...state,
        donations: state.donations.map((donation) =>
          donation.id === action.payload
            ? { ...donation, approved: false, rejected: true }
            : donation
        ),
      };
    case "REPLAY_DONATION":
      return state; // Define the behavior for replaying a donation if needed
    default:
      return state;
  }
}

export default function DonationContextProvider({ children }) {
  const [donationsState, donationsDispatch] = useReducer(donationReducer, {
    donations: [],
    currentPage: 1,
    totalPages: 1,
  });

  const { token } = useContext(AuthContext);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (token) {
      const fetchDonations = async (page) => {
        try {
          const requestOptions = {
            method: "GET",
            headers: { Authorization: `${token}` },
          };

          const response = await fetch(
            `${backendUrl}/donations?limit=20&offset=${(page - 1) * 20}`,
            requestOptions
          );

          if (!response.ok) {
            const errorText = await response.text();
            throw new Error(
              `Fetch donations failed: ${response.status} ${errorText}`
            );
          }

          const data = await response.json();
          donationsDispatch({
            type: "ADD_DONATIONS",
            payload: {
              donations: data.donations || [], // Ensure donations is always an array
              currentPage: page,
              totalPages: data.total_pages,
            },
          });
        } catch (error) {
          console.error("Fetch donations error:", error);
        }
      };

      fetchDonations(currentPage);
      const intervalId = setInterval(() => fetchDonations(currentPage), 5000); // Refresh every 60 seconds

      return () => clearInterval(intervalId); // Cleanup interval on component unmount
    }
  }, [token, currentPage]);

  function handleAddDonations(donations) {
    donationsDispatch({
      type: "ADD_DONATIONS",
      payload: donations,
    });
  }

  async function handleAcceptDonation(donationID) {
    try {
      const requestOptions = {
        method: "PUT",
        headers: { Authorization: `${token}` },
      };

      const response = await fetch(
        `${backendUrl}/donations/${donationID}/approve`,
        requestOptions
      );

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(
          `Accept donation failed: ${response.status} ${errorText}`
        );
      }

      donationsDispatch({
        type: "ACCEPT_DONATION",
        payload: donationID,
      });
    } catch (error) {
      console.error("Accept donation error:", error);
    }
  }

  async function handleRejectDonation(donationID) {
    try {
      const requestOptions = {
        method: "PUT",
        headers: { Authorization: `${token}` },
      };

      const response = await fetch(
        `${backendUrl}/donations/${donationID}/reject`,
        requestOptions
      );

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(
          `Reject donation failed: ${response.status} ${errorText}`
        );
      }

      donationsDispatch({
        type: "REJECT_DONATION",
        payload: donationID,
      });
    } catch (error) {
      console.error("Reject donation error:", error);
    }
  }

  function handleReplayDonation(donationID) {
    donationsDispatch({
      type: "REPLAY_DONATION",
      payload: donationID,
    });
  }

  const ctxValue = {
    donations: donationsState.donations,
    addDonations: handleAddDonations,
    acceptDonation: handleAcceptDonation,
    rejectDonation: handleRejectDonation,
    replayDonation: handleReplayDonation,
    setPage: setCurrentPage,
    currentPage: donationsState.currentPage,
    totalPages: donationsState.totalPages,
  };

  return (
    <DonationsContext.Provider value={ctxValue}>
      {children}
    </DonationsContext.Provider>
  );
}
