import { useContext, useState } from "react";
import { SubsContext } from "../../store/subs-context";
import SubTableEntry from "./SubTableEntry";

export default function SubTableEntries() {
  const { subs, currentPage, totalPages, setPage } = useContext(SubsContext);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const handlePreviousPage = async () => {
    if (currentPage > 1) {
      setIsLoading(true);
      setError(null);
      try {
        await setPage(currentPage - 1);
      } catch (err) {
        setError("Failed to load previous page");
      }
      setIsLoading(false);
    }
  };

  const handleNextPage = async () => {
    if (currentPage < totalPages) {
      setIsLoading(true);
      setError(null);
      try {
        await setPage(currentPage + 1);
      } catch (err) {
        setError("Failed to load next page");
      }
      setIsLoading(false);
    }
  };

  return (
    <>
      {error && <div className="error">{error}</div>}
      <tbody>
        {(subs || []).map((sub) => (
          <SubTableEntry key={sub.id} sub={sub} />
        ))}
      </tbody>
      <tfoot>
        <tr>
          <td colSpan="4" className="text-center py-4">
            <div className="flex justify-center items-center space-x-4">
              <button
                onClick={handlePreviousPage}
                disabled={currentPage === 1 || isLoading}
                className={`px-3 py-1 border rounded ${
                  currentPage === 1
                    ? "bg-gray-400"
                    : "bg-blue-500 hover:bg-blue-700"
                } text-white`}
              >
                {isLoading ? "Loading..." : "Previous"}
              </button>
              <span className="text-white">{`Page ${currentPage} of ${totalPages}`}</span>
              <button
                onClick={handleNextPage}
                disabled={currentPage === totalPages || isLoading}
                className={`px-3 py-1 border rounded ${
                  currentPage === totalPages
                    ? "bg-gray-400"
                    : "bg-blue-500 hover:bg-blue-700"
                } text-white`}
              >
                {isLoading ? "Loading..." : "Next"}
              </button>
            </div>
          </td>
        </tr>
      </tfoot>
    </>
  );
}
