import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../store/auth-context";

const ApiKeyInput = ({ title, url }) => {
  const [apiKey, setApiKey] = useState("");
  const [isKeySet, setIsKeySet] = useState(false);
  const { token } = useContext(AuthContext);

  useEffect(() => {
    // Fetch the current API key status from the backend
    const checkApiKey = async () => {
      try {
        const requestOptions = {
          method: "GET",
          headers: { Authorization: `${token}` },
        };

        const response = await fetch(url, requestOptions);
        const result = await response.json();
        setIsKeySet(result.keySet);
      } catch (error) {
        console.error("Error checking API key status:", error);
      }
    };

    checkApiKey();
  }, [url, token]);

  const handleApiKeyChange = (e) => {
    setApiKey(e.target.value);
  };

  const handleSaveApiKey = async () => {
    try {
      const requestOptions = {
        method: "POST",
        headers: { Authorization: `${token}` },
      };

      const response = await fetch(url + `?apikey=${apiKey}`, requestOptions);

      if (response.ok) {
        setIsKeySet(true);
        setApiKey("");
      } else {
        console.error("Error setting API key");
      }
    } catch (error) {
      console.error("Error setting API key:", error);
    }
  };

  return (
    <div className="mb-4">
      {isKeySet ? (
        <p className="text-green-400">{title} is set</p>
      ) : (
        <div className="flex items-center space-x-4">
          <div className="flex-1">
            <label
              className="block text-gray-300 text-sm font-bold mb-2"
              htmlFor="api-key"
            >
              {title}
            </label>
            <input
              type="text"
              id="api-key"
              value={apiKey}
              onChange={handleApiKeyChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 bg-gray-700 text-gray-300 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <button
            onClick={handleSaveApiKey}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-6"
            disabled={!apiKey}
          >
            Save {title}
          </button>
        </div>
      )}
    </div>
  );
};

export default ApiKeyInput;
