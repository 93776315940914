import React, { useRef } from "react";
import logo from "../logo180.png";
import SettingsModal from "./Settings/SettingsModal";

const Header = () => {
  const modal = useRef();

  function handleOpenSettingsClick() {
    modal.current.open();
  }

  return (
    <>
      <SettingsModal ref={modal} />
      <header className="flex items-center justify-between bg-zinc-800 p-4 shadow-md">
        <div className="flex items-center space-x-4">
          <img src={logo} className="h-16" alt="logo" />
          <h1 className="text-2xl font-bold text-white">Donation Dashboard</h1>
        </div>
        <button
          onClick={handleOpenSettingsClick}
          className="text-white bg-blue-500 hover:bg-blue-700 font-bold py-2 px-4 rounded"
        >
          Settings
        </button>
      </header>
    </>
  );
};

export default Header;
