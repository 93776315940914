import React, { useState, forwardRef, useImperativeHandle } from "react";
import { createPortal } from "react-dom";
import ApiKeyInput from "./ApiKeyInput";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const apiKeys = [
  {
    title: "Streamlabs API Key",
    url: `${backendUrl}/streamlabskey`,
  },
  {
    title: "Elevenlabs API Key",
    url: `${backendUrl}/elevenlabskey`,
  },
];

const SettingsModal = forwardRef((props, ref) => {
  const [isOpen, setIsOpen] = useState(false);

  useImperativeHandle(ref, () => ({
    open: () => {
      setIsOpen(true);
    },
    close: () => {
      setIsOpen(false);
    },
  }));

  const handleClose = () => {
    setIsOpen(false);
  };

  return isOpen
    ? createPortal(
        <>
          <div className="fixed inset-0 bg-black bg-opacity-50 z-40"></div>
          <dialog
            open
            className="bg-gray-800 text-white rounded p-6 shadow-lg max-w-3xl mx-auto mt-20 z-50"
          >
            <h2 className="text-2xl font-bold mb-4">Settings</h2>
            {apiKeys.map((apiKey) => {
              return <ApiKeyInput key={apiKey.title} title={apiKey.title} url={apiKey.url} />;
            })}
            <div className="flex justify-end mt-4">
              <button
                onClick={handleClose}
                className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
              >
                Close
              </button>
            </div>
          </dialog>
        </>,
        document.getElementById("modal")
      )
    : null;
});

export default SettingsModal;
