// src/components/DonationTable.js
import React from "react";
import DonationTableHeader from "./DonationTableHeader";
import DonationTableEntries from "./DonationTableEntries";

const DonationTable = () => {
  return (
    <table className="min-w-full bg-zinc-800">
      <DonationTableHeader />
      <DonationTableEntries />
    </table>
  );
};

export default DonationTable;
